import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AboutUsLayout from '../../components/AboutUsLayout';
import SEO from '../../components/SEO/SEO';
import AboutUsPage1Desktop from '../../assets/images/about_us_page1_desktop.jpg';
import AboutUsPage1Mobile from '../../assets/images/about_us_page1_mobile.jpg';
import './about-page.scss';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';

const CorporateInformation = injectIntl(({ intl, location }) => {
  const visitInvestorCentre = (
    <Link to="https://investor.capitalone.com/" target="_blank" rel="noopener noreferrer">
      <FormattedMessage id="pages.about.corporate-information.visit-investor-centre" />
    </Link>
  );
  return (
    <AboutUsLayout intl={intl} location={location} pageName="about-us-page">
      <section className="corporation-info-wrapper">
        <div>
          <img src={AboutUsPage1Desktop} alt="" />
          <header>
            <h2>
              <FormattedMessage id="pages.about.corporate-information.title" />
            </h2>
          </header>
          <div className="who-we-are-container">
            <img src={AboutUsPage1Mobile} className="corp-image-mobile-image" alt="" />
            <div className="who-we-are-content">
              <h3>
                <FormattedMessage id="pages.about.corporate-information.sub-title" />
              </h3>
              <p>
                <FormattedMessage id="pages.about.corporate-information.paragraph1" />
              </p>
              <p>
                <FormattedMessage id="pages.about.corporate-information.paragraph2" />
              </p>
              <p>
                <FormattedMessage id="pages.about.corporate-information.paragraph3" />
              </p>
              <p>
                <FormattedMessage id="pages.about.corporate-information.paragraph4" values={{ visitInvestorCentre }} />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="our-leaders-wrapper">
        <header>
          <h2>
            <FormattedMessage id="pages.about.corporate-information.our-leaders-title" />
          </h2>
        </header>
        <div className="our-leaders-content">
          <section>
            <p className="name">
              <FormattedMessage id="pages.about.corporate-information.leader1.name" />
            </p>
            <p>
              <FormattedMessage id="pages.about.corporate-information.leader1.position" />
            </p>
            <p className="company">
              <FormattedMessage id="pages.about.corporate-information.leader1.company" />
            </p>
          </section>
          <section>
            <p className="name">
              <FormattedMessage id="pages.about.corporate-information.leader2.name" />
            </p>
            <p>
              <FormattedMessage id="pages.about.corporate-information.leader2.position" />
            </p>
            <p className="company">
              <FormattedMessage id="pages.about.corporate-information.leader2.company" />
            </p>
          </section>
        </div>
      </section>
      <section className="where-we-located-wrapper">
        <header>
          <h2>
            <FormattedMessage id="pages.about.corporate-information.location-title" />
          </h2>
        </header>
        <div className="where-we-located-content">
          <section>
            <p className="name">
              <FormattedMessage id="pages.about.corporate-information.location1.name" />
            </p>
            <p>
              <FormattedMessage id="pages.about.corporate-information.location1.city1" />
            </p>
          </section>
          <section>
            <p className="name">
              <FormattedMessage id="pages.about.corporate-information.location2.name" />
            </p>
            <p>
              <FormattedMessage id="pages.about.corporate-information.location2.city1" />
            </p>
          </section>
        </div>
      </section>
    </AboutUsLayout>
  );
});

export default CorporateInformation;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.about.corporate-information.page-title"
    descriptionKey="pages.about.corporate-information.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
